<script lang="ts">
export default {
  name: 'UserAccountMenu',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, inject, ref, Ref } from 'vue';
import Avatar from '@/components/atoms/Avatar/Index.vue';
import FullDescription from '@/components/molecules/Avatar/FullDescription.vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuDivider from '@/components/atoms/Popover/PopoverMenuDivider/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuButton from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuButton.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
import LinkedAccountsSwitcher from '@/components/atoms/LinkedAccountsSwitcher/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { ICurrentUser } from '@/types/Navigation';
import { UserMenu, BaseMenuLink } from '@/json_schema_types/sidebar_navigation';
import { ILanguageLink, languageLinksKey } from '@/types/InjectionKeys';
interface Props {
  currentUser: ICurrentUser;
  userMenu: UserMenu;
  company: {
    name: string;
    logo: string;
  };
}

const props = defineProps<Props>();
const { t } = useI18n();

const menuOpen: Ref<boolean> = ref(false);
const privacySubMenuOpen: Ref<boolean> = ref(false);
const languageSubMenuOpen: Ref<boolean> = ref(false);
const languages: ILanguageLink[] | undefined = inject(languageLinksKey);

const signOutLink: BaseMenuLink = {
  url: props.currentUser.signOutPath,
  label: t('vue_templates.user_account_menu.sign_out'),
  icon: 'fa-arrow-right-from-bracket',
};

const isActiveLocale = (locale: string) => {
  return locale === window.I18n.locale;
};
</script>

<template>
  <div class="user-account-menu">
    <NavigationHeaderItem
      :aria-label="t('vue_templates.user_account_menu.aria_label')"
      aria-haspopup="true"
      :aria-expanded="menuOpen"
      aria-controls="user-account-popover-menu"
      class="user-account-menu__button"
      data-test-id="user-account-menu-button"
      @click.prevent="menuOpen = !menuOpen"
      @keyup.enter="menuOpen = !menuOpen"
    >
      <Avatar :src="currentUser.avatar" size="small" /><span class="user-account-menu__full-name">{{
        currentUser.name
      }}</span>
    </NavigationHeaderItem>
    <PopoverMenu
      id="user-account-popover-menu"
      class="user-account-menu__popover-menu"
      position="bottom"
      toggle-element-selector="user-account-menu__button"
      :model-value="menuOpen"
      @update:model-value="menuOpen = $event"
    >
      <PopoverMenuItem class="hero-banner" height="100px" flex-wrap="wrap" padding="1rem 2.5rem">
        <FullDescription
          :avatar-url="currentUser.avatar"
          :full-name="currentUser.full_name || currentUser.name"
          truncate-name
        >
          <template #additional-description>
            <div
              class="user-account-menu-full-description__company-info-wrapper"
              data-test-id="company-info"
            >
              <div class="user-account-menu-full-description__company-logo">
                <img :src="company.logo" :alt="company.name" />
              </div>
              <span>{{ company.name }}</span>
            </div>
          </template>
        </FullDescription>
        <div style="display: flex; flex-direction: row">
          <LinkedAccountsSwitcher
            v-if="userMenu.linked_accounts_switcher"
            :switcher-data="userMenu.linked_accounts_switcher"
          />
        </div>
      </PopoverMenuItem>
      <PopoverMenuItem>
        <PopoverMenuLink
          :item="{
            url: currentUser.show_path,
            label: t('vue_templates.user_account_menu.profile'),
            icon: 'fa-user',
          }"
        />
      </PopoverMenuItem>
      <PopoverMenuItem>
        <PopoverMenuLink
          :item="{
            url: currentUser.preferencesPath,
            label: t('vue_templates.user_account_menu.preferences'),
            icon: 'fa-gear',
          }"
        />
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <PopoverMenuItem>
        <PopoverMenuButton
          v-if="languages"
          :item="{
            label: t('vue_templates.user_account_menu.language'),
            icon: 'fa-language',
          }"
          aria-haspopup="true"
          aria-controls="privacy-and-policies-popover-menu"
          :aria-expanded="languageSubMenuOpen"
          class="user-account-menu__language-button"
          :has-sub-menu="true"
          :active="languageSubMenuOpen"
          @click="languageSubMenuOpen = !languageSubMenuOpen"
          @keyup.enter="languageSubMenuOpen = !languageSubMenuOpen"
        />
        <PopoverMenu
          v-if="languages"
          id="languages-popover-menu"
          position="left"
          toggle-element-selector="user-account-menu__language-button"
          :model-value="languageSubMenuOpen"
          @update:model-value="languageSubMenuOpen = $event"
        >
          <template v-for="(language, index) in languages" :key="`language-option-${index}`">
            <PopoverMenuItem>
              <PopoverMenuLink
                :item="{
                  label: language.language,
                  url: language.url,
                  after_icon: isActiveLocale(language.locale) ? 'fa-check' : undefined,
                } as BaseMenuLink"
              />
            </PopoverMenuItem>
          </template>
        </PopoverMenu>
      </PopoverMenuItem>
      <PopoverMenuItem v-if="userMenu.compliance_navigation_items">
        <PopoverMenuButton
          :item="{
            label: t('vue_templates.user_account_menu.privacy_and_policies'),
            icon: 'fa-scale-unbalanced-flip',
          }"
          :has-sub-menu="true"
          aria-haspopup="true"
          aria-controls="privacy-and-policies-popover-menu"
          class="user-account-menu__privacy-and-policies-button"
          :aria-expanded="privacySubMenuOpen"
          :active="privacySubMenuOpen"
          data-test-id="privacy-and-policies-popover-menu"
          @click="privacySubMenuOpen = !privacySubMenuOpen"
          @keyup.enter="privacySubMenuOpen = !privacySubMenuOpen"
        />
        <PopoverMenu
          id="privacy-and-policies-popover-menu"
          position="left"
          toggle-element-selector="user-account-menu__privacy-and-policies-button"
          :model-value="privacySubMenuOpen"
          @update:model-value="privacySubMenuOpen = $event"
        >
          <template v-for="(item, index) in userMenu.compliance_navigation_items" :key="index">
            <PopoverMenuItem>
              <PopoverMenuLink :item="item as BaseMenuLink" open-new-tab />
            </PopoverMenuItem>
          </template>
        </PopoverMenu>
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <PopoverMenuItem>
        <PopoverMenuLink :item="signOutLink" data-method="delete" />
      </PopoverMenuItem>
    </PopoverMenu>
  </div>
</template>

<style lang="scss" scoped>
$user-menu-width: 200px;

.user-account-menu {
  color: inherit;
  margin-left: 3rem;
  position: relative;

  &__full-name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: $user-menu-width - 17px;
  }

  &__popover-menu {
    padding-top: 0;
    position: fixed;
    top: var(--navigation-height);
    right: 14px;
    max-width: $user-menu-width;
  }
}

.user-account-menu-full-description {
  &__company-info-wrapper {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
  }

  &__company-logo {
    display: inline-block;
    margin-right: 0.5rem;
    border: 1px solid $cool-grey;
    border-radius: 100%;
    img {
      height: 16px;
      width: 16px;
      border-radius: 100%;
    }
  }
}
</style>
