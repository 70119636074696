<script lang="ts">
export default {
  name: 'PopoverMenuItem',
  compatConfig: { MODE: 3 },
};
</script>
<script setup lang="ts">
import { computed, defineProps, CSSProperties } from 'vue';
const props = defineProps<{
  padding?: string;
  flexWrap?: CSSProperties['flexWrap'];
  height?: string;
}>();

const customStyles = computed(() => ({
  padding: props.padding,
  flexWrap: props.flexWrap,
  height: props.height,
}));
</script>
<template>
  <div class="popover-menu-item" :style="customStyles">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.popover-menu-item {
  justify-content: flex-start;
  align-items: center;
  color: $feldgrau;
  padding: 0.5rem 1rem;
  display: flex;
  position: relative;
}
</style>
